import {
  faAt,
  faCheck,
  faCircleExclamation,
  faKey,
  faLock,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, ConfigProvider, Form, Input, theme, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  createSearchParams,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AccountContext } from "../../Contexts";
import AgreementModal from "../../components/AgreementModal";
import Card from "../../components/Card";
import APIManager from "../../scripts/APIManager";
import AuthManager from "../../scripts/AuthManager";
import constants from "../../constants";
import LandingHeader from "../../components/landing/LandingHeader";
import Eye from "../../components/Eye";
import LandingFooter from "../../components/landing/LandingFooter";

export default function SignUp() {
  const { signedIn, setSignedIn } = useContext(AccountContext);
  const [mode, setMode] = useState(null);
  const [result, setResult] = useState(null);
  const [username, setUsername] = useState(null);
  const [usernameStatus, setUsernameStatus] = useState(null);
  const [usernameWarning, setUsernameWarning] = useState(null);
  const [email, setEmail] = useState(null);
  const [emailStatus, setEmailStatus] = useState(null);
  const [emailWarning, setEmailWarning] = useState(null);
  const [password, setPassword] = useState(null);
  const [passwordStatus, setPasswordStatus] = useState(null);
  const [passwordReqs, setPasswordReqs] = useState({
    number: false,
    special: false,
    upper: false,
    lower: false,
    length: false,
  });
  const [passwordConfirm, setPasswordConfirm] = useState(null);
  const [passwordConfirmStatus, setPasswordConfirmStatus] = useState(null);
  const [passwordConfirmWarning, setPasswordConfirmWarning] = useState(null);
  const [code, setCode] = useState(null);
  const [warning, setWarning] = useState(null);
  const [tosActive, setTosActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const checkAvailable = useCallback(
    async () =>
      await APIManager.sendRequest("check_account", {
        username,
        email,
      }).then((data) => {
        if (data.error) return false;
        else {
          if (!data.username_available) {
            setUsernameStatus("error");
            setUsernameWarning("This username is taken");
          }
          if (!data.email_available) {
            setEmailStatus("error");
            setEmailWarning(
              "An account is already associated with this email address"
            );
          }
          return data.available;
        }
      }),
    [username, email]
  );

  const checkUsername = useCallback(() => {
    if (!username || username === "") {
      setUsernameStatus(null);
      setUsernameWarning(null);
      return true;
    } else if (
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(username) &&
      mode === "signUp"
    ) {
      setUsernameStatus("error");
      setUsernameWarning("Username cannot be an email");
      return false;
    } else {
      setUsernameStatus(null);
      setUsernameWarning(null);
      return true;
    }
  }, [username, mode]);

  const checkEmail = useCallback(() => {
    if (!email || email === "") {
      setEmailStatus(null);
      setEmailWarning(null);
      return true;
    } else if (
      /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email) &&
      mode === "signUp"
    ) {
      setEmailStatus(null);
      setEmailWarning(null);
      return true;
    } else {
      setEmailStatus("error");
      setEmailWarning("Must be email format");
      return false;
    }
  }, [email, mode]);

  const checkPassword = useCallback(() => {
    if (!password || password === "") {
      setPasswordStatus(null);
      return true;
    } else if (
      (Object.values(passwordReqs).every((value) => value === true) &&
        mode === "signUp") ||
      mode !== "signUp"
    ) {
      setPasswordStatus(null);
      return true;
    } else {
      setPasswordStatus("error");
      return false;
    }
  }, [password, passwordReqs, mode]);

  const checkPasswordConfirm = useCallback(() => {
    let valid = password === passwordConfirm;
    setPasswordConfirmStatus(valid ? null : "error");
    setPasswordConfirmWarning(valid ? null : "Must match");
    return valid;
  }, [password, passwordConfirm]);

  useEffect(() => {
    if (result === "verify") setMode("verify");
    switch (result) {
      case "verify":
        setMode("verify");
        break;
      case "success":
        setSignedIn(true);
        const urlParams = new URLSearchParams(location.search);
        let toGuild = urlParams.get("to_guild");
        let planParam = urlParams.get("plan");
        let toAtlassianLink = urlParams.get("to_atlassian_link");
        if (toGuild) navigate(`/${toGuild}`, { replace: true });
        else if (toAtlassianLink)
          navigate({
            pathname: "/home/atlassian_link",
            search: `?${createSearchParams({ url: toAtlassianLink })}`,
          });
        else if (planParam)
          navigate(`/home?plan=${planParam}`, { replace: true });
        else navigate("/home", { replace: true });
        break;
      default:
        setWarning(result);
        break;
    }
  }, [result, navigate, setSignedIn, location]);

  useEffect(() => setWarning(null), [mode]);

  useEffect(() => {
    if (signedIn) {
      const urlParams = new URLSearchParams(location.search);
      let toGuild = urlParams.get("to_guild");
      let toAtlassianLink = urlParams.get("to_atlassian_link");
      if (toGuild) navigate(`/${toGuild}`, { replace: true });
      else if (toAtlassianLink)
        navigate({
          pathname: "/home/atlassian_link",
          search: `?${createSearchParams({ url: toAtlassianLink })}`,
        });
      else navigate("/home", { replace: true });
    }
  }, [signedIn, location, navigate]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    let pageMode = urlParams.get("mode");
    if (pageMode && ["signIn", "signUp"].includes(pageMode)) setMode(pageMode);
    else setMode("signIn");
  }, [location]);

  useEffect(() => {
    setPasswordReqs({
      number: /\d/.test(password),
      special: /[^\w\s]/.test(password),
      lower: /[a-z]/.test(password) && password !== null,
      upper: /[A-Z]/.test(password),
      length: /^.{8,}$/.test(password),
    });
  }, [password, setPasswordReqs]);

  useEffect(() => {
    setUsernameStatus(null);
    setUsernameWarning(null);
    setEmailStatus(null);
    setEmailWarning(null);
    setPasswordStatus(null);
  }, [mode]);

  if (signedIn === null || signedIn || mode === null)
    return (
      <div className="relative no-zoom">
        <div className="-z-10 absolute inset-0 bg-white" />
        <div className="-z-10 absolute w-full flex flex-row justify-center top-[50px]">
          <div className="landing-bg h-[400px] w-[400px]" />
        </div>
        <ConfigProvider
          theme={{
            algorithm: theme.defaultAlgorithm,
            token: {
              colorPrimary: constants.colors["secondary"],
            },
          }}
        >
          <div className="static min-h-screen flex flex-col">
            <LandingHeader
              simple
              landing
              className="fixed box-border h-12 sm:h-16 w-full"
            />
          </div>
        </ConfigProvider>
      </div>
    );

  return (
    <div className="relative no-zoom">
      <div className="-z-10 absolute inset-0 bg-white" />
      <div className="-z-10 absolute w-full flex flex-row justify-center top-[50px]">
        <div className="landing-bg h-[400px] w-[400px]" />
      </div>
      <ConfigProvider
        theme={{
          algorithm: theme.defaultAlgorithm,
          token: {
            colorPrimary: constants.colors["secondary"],
          },
        }}
      >
        <div className="static min-h-screen flex flex-col">
          <LandingHeader
            simple
            landing
            className="fixed box-border h-12 sm:h-16 w-full"
          />
          <div className="h-16 sm:h-0" />
          <Form className="flex flex-col items-center justify-center gap-4 sm:min-h-screen sm:py-20">
            <div className="flex flex-row items-center gap-2">
              <Eye height={24} width={30} />
              <p className="font-landing-header text-xl text-secondary font-semibold">
                {mode === "signIn"
                  ? "Sign In"
                  : mode === "signUp"
                  ? "Sign Up"
                  : "Verify Your Email"}
              </p>
            </div>
            <Card
              noMode
              className="h-fit bg-black/10 border-black/20 border-[1px] border-solid self-stretch sm:self-center sm:w-96 mx-8"
            >
              <div className="flex flex-col gap-1">
                {mode !== "verify" ? (
                  <Tooltip title={usernameWarning} color="red">
                    <label className="flex flex-col gap-px">
                      <p className="font-landing text-black">Username</p>
                      <Input
                        name="username"
                        title="Username"
                        onFocus={() => {
                          setUsernameStatus(null);
                          setUsernameWarning(null);
                        }}
                        onBlur={checkUsername}
                        status={usernameStatus}
                        prefix={
                          <FontAwesomeIcon
                            icon={faUser}
                            size="sm"
                            className="mr-1"
                          />
                        }
                        size="large"
                        placeholder="Enter Username"
                        onChange={({ target }) => setUsername(target.value)}
                        value={username}
                      />
                    </label>
                  </Tooltip>
                ) : null}
                {mode === "verify" ? (
                  <label className="flex flex-col gap-px">
                    <p className="font-landing text-black">Verification Code</p>
                    <Input
                      prefix={
                        <FontAwesomeIcon
                          icon={faKey}
                          size="sm"
                          className="mr-1"
                        />
                      }
                      size="large"
                      placeholder="Enter Code"
                      onChange={({ target }) => setCode(target.value)}
                      value={code}
                    />
                  </label>
                ) : null}
                {mode === "signUp" ? (
                  <Tooltip title={emailWarning} color="red">
                    <label className="flex flex-col gap-px">
                      <p className="font-landing text-black">Email</p>
                      <Input
                        name="email"
                        title="Email"
                        onFocus={() => {
                          setEmailStatus(null);
                          setEmailWarning(null);
                        }}
                        onBlur={() => {
                          checkEmail();
                        }}
                        status={emailStatus}
                        prefix={
                          <FontAwesomeIcon
                            icon={faAt}
                            size="sm"
                            className="mr-1"
                          />
                        }
                        size="large"
                        placeholder="Enter Email"
                        onChange={({ target }) => setEmail(target.value)}
                        value={email}
                      />
                    </label>
                  </Tooltip>
                ) : null}
                {mode !== "verify" ? (
                  <div>
                    <label className="flex flex-col gap-px">
                      <p className="font-landing text-black">Password</p>
                      <Input.Password
                        title="Password"
                        onFocus={() => {
                          setPasswordStatus(null);
                        }}
                        onBlur={() => {
                          checkPassword();
                        }}
                        status={passwordStatus}
                        prefix={
                          <FontAwesomeIcon
                            icon={faLock}
                            size="sm"
                            className="mr-1"
                          />
                        }
                        size="large"
                        placeholder="Enter Password"
                        onChange={({ target }) => setPassword(target.value)}
                        value={password}
                      />
                    </label>
                    {mode === "signUp" ? (
                      <div className="pl-3 pt-1">
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.length ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.length
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 8 letters
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.number ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.number
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 number
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.special ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.special
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 special character
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.lower ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.lower
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 lowercase letter
                        </p>
                        <p className="font-heading text-black text-xs -my-px">
                          <FontAwesomeIcon
                            icon={passwordReqs.upper ? faCheck : faXmark}
                            size="xs"
                            className={`w-3 ${
                              passwordReqs.upper
                                ? "text-green-dark"
                                : "text-red-dark"
                            }`}
                          />{" "}
                          Contains at least 1 uppercase letter
                        </p>
                      </div>
                    ) : (
                      <p className="font-heading text-xs text-black font-light mt-1">
                        <Link to="/forgot_password">Forgot Password?</Link>
                      </p>
                    )}
                  </div>
                ) : null}
                {mode === "signUp" ? (
                  <Tooltip title={passwordConfirmWarning} color="red">
                    <label className="flex flex-col gap-px">
                      <p className="font-landing text-black">
                        Confirm Password
                      </p>
                      <Input.Password
                        title="Confirm Password"
                        onFocus={() => {
                          setPasswordConfirmStatus(null);
                          setPasswordConfirmWarning(null);
                        }}
                        onBlur={() => {
                          checkPasswordConfirm();
                        }}
                        status={passwordConfirmStatus}
                        prefix={
                          <FontAwesomeIcon
                            icon={faLock}
                            size="sm"
                            className="mr-1"
                          />
                        }
                        size="large"
                        placeholder="Confirm Password"
                        onChange={({ target }) =>
                          setPasswordConfirm(target.value)
                        }
                        value={passwordConfirm}
                      />
                    </label>
                  </Tooltip>
                ) : null}
                {mode === "signUp" ? (
                  <Button
                    disabled={tosActive}
                    type="primary"
                    className="self-center w-[80%] h-16 bg-[#FFD86D] my-4"
                    onClick={() => {
                      let available = false;
                      checkAvailable().then((result) => {
                        if (result) {
                          available = true;
                        }
                        if (
                          available &&
                          username &&
                          checkUsername() &&
                          email &&
                          checkEmail() &&
                          password &&
                          checkPassword() &&
                          passwordConfirm &&
                          checkPasswordConfirm()
                        ) {
                          setWarning(null);
                          setTosActive(true);
                        } else {
                          setWarning("Some fields have problems");
                          if (!username) {
                            setUsernameStatus("error");
                            setUsernameWarning("Field cannot be empty");
                          }
                          if (!email) {
                            setEmailStatus("error");
                            setEmailWarning("Field cannot be empty");
                          }
                          if (!password) setPasswordStatus("error");
                          if (!passwordConfirm)
                            setPasswordConfirmStatus("error");
                        }
                      });
                    }}
                    htmlType="submit"
                  >
                    <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                      Register
                    </span>
                  </Button>
                ) : mode === "signIn" ? (
                  <Button
                    type="primary"
                    className="self-center w-[80%] h-16 bg-[#FFD86D] my-4"
                    onClick={() => {
                      if (username && password)
                        AuthManager.signIn(username, password, setResult);
                      else {
                        setWarning("You must fill all fields");
                        if (!username) setUsernameStatus("error");
                        if (!password) setPasswordStatus("error");
                      }
                    }}
                    htmlType="submit"
                  >
                    <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                      Sign In
                    </span>
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className="self-center w-[80%] h-16 bg-[#FFD86D] my-4"
                    onClick={() =>
                      AuthManager.verify(username, password, code, setResult)
                    }
                    htmlType="submit"
                  >
                    <span className="font-landing-header capitalize text-2xl font-semibold text-black">
                      Verify
                    </span>
                  </Button>
                )}
                {warning ? (
                  <div className="flex flex-row items-center gap-1">
                    <FontAwesomeIcon
                      className="text-red-dark"
                      icon={faCircleExclamation}
                      size="sm"
                    />
                    <p className="text-sm text-red-dark">{warning}</p>
                  </div>
                ) : null}
                <div className="bg-black/25 h-px" />
                {mode === "signUp" ? (
                  <p className="font-heading text-xs text-black font-light">
                    Already signed up?{" "}
                    <Link
                      className="underline decoration-from-font"
                      onClick={(event) => {
                        event.preventDefault();
                        setMode("signIn");
                      }}
                    >
                      Sign In.
                    </Link>
                  </p>
                ) : (
                  <>
                    <p className="font-heading text-xs text-black font-light">
                      Don't have an account?{" "}
                      <Link
                        className="underline decoration-from-font"
                        onClick={(event) => {
                          event.preventDefault();
                          setMode("signUp");
                        }}
                      >
                        Sign Up.
                      </Link>
                    </p>
                  </>
                )}
              </div>
            </Card>
            <AgreementModal
              tosActive={tosActive}
              setTosActive={setTosActive}
              username={username}
              password={password}
              email={email}
              setResult={setResult}
            />
          </Form>
          <LandingFooter noRegister />
        </div>
      </ConfigProvider>
    </div>
  );
}
