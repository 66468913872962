import { Navigate, Outlet, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import AccountContainer from "./components/AccountContainer";
import SiteContainer from "./components/SiteContainer";
import ThemeContainer from "./components/ThemeContainer";
import AdminPage from "./pages/AdminPage";
import DiscordAuth from "./pages/DiscordAuth";
import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Questions from "./pages/analytics/Questions";
import SignOut from "./pages/SignOut";
import Suggestions from "./pages/Suggestions";
import DisplayReports from "./pages/analytics/BugReports";
import Interactions from "./pages/analytics/Interactions";
import Messages from "./pages/analytics/Messages";
import { default as AnalyticsModeration } from "./pages/analytics/Moderation";
import SummaryDashboard from "./pages/analytics/SummaryDashboard";
import Timeline from "./pages/analytics/Timeline";
import TopIssues from "./pages/analytics/TopIssues";
import AboutUs from "./pages/landing/AboutUs";
import Contact from "./pages/landing/Contact";
import ForgotPassword from "./pages/landing/ForgotPassword";
import Main from "./pages/landing/Main";
import PrivacyPolicy from "./pages/landing/PrivacyPolicy";
import SignUp from "./pages/landing/SignUp";
import Subscribe from "./pages/landing/Subscribe";
import TermsOfService from "./pages/landing/TermsOfService";
import ActionTracking from "./pages/moderation/ActionTracking";
import ConfigurationDashboard from "./pages/moderation/ConfigurationDashboard";
import Moderation from "./pages/moderation/Moderation";
import RulePage from "./pages/moderation/RulePage";
import UniversalRulePage from "./pages/moderation/UniversalRulePage";
import Issues from "./pages/issues/Issues";
import AtlassianForgeLink from "./pages/AtlassianForgeLink";

function App() {
  const RulePageWrapper = () => {
    const { ruleId } = useParams();
    return <RulePage key={ruleId} ruleId={ruleId} />;
  };

  return (
    <Routes>
      <Route path="/" element={<AccountContainer />}>
        <Route path="/" element={<ThemeContainer />}>
          <Route index element={<Main />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/cases" element={<Cases />} /> */}
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/tos" element={<TermsOfService />} />
          <Route path="/subscribe" element={<Subscribe />} />
          <Route path="/login" element={<SignUp />} />
          <Route path="/forgot_password" element={<ForgotPassword />} />
          <Route path="/signout" element={<SignOut />} />
          <Route path="/home">
            <Route index element={<Home />} />
            <Route path="discord_auth" element={<DiscordAuth />} />
            <Route path="atlassian_link" element={<AtlassianForgeLink />} />
          </Route>
          <Route path=":guildId" element={<SiteContainer />}>
            {/* <Route index element={<Navigate to="configuration" />} /> */}
            <Route path="moderation" element={<Outlet />}>
              <Route
                index
                element={<Navigate to="dashboard" replace />}
              ></Route>
              <Route path="actions" element={<ActionTracking />} />
              <Route path="incidents" element={<Moderation />} />
              <Route path="dashboard" element={<ConfigurationDashboard />} />
              <Route path="universal" element={<UniversalRulePage />} />
              <Route path="rule/:ruleId" element={<RulePageWrapper />} />
            </Route>
            <Route path="analytics" element={<Outlet />}>
              <Route index element={<Navigate to="bug_reports" replace />} />
              <Route path="bug_reports" element={<DisplayReports />} />
              <Route path="summary_dashboard" element={<SummaryDashboard />} />
              <Route path="q_and_a" element={<Questions />} />
              <Route path="top_issues" element={<TopIssues />} />
              <Route path="messages" element={<Messages />} />
              <Route path="interactions" element={<Interactions />} />
              <Route path="timeline" element={<Timeline />} />
              <Route path="moderation" element={<AnalyticsModeration />} />
            </Route>
            <Route path="issues" element={<Issues />} />
            <Route path="admin" element={<AdminPage />} />
            <Route path="manage">
              <Route path="suggestions" element={<Suggestions />} />
            </Route>
          </Route>
          <Route
            path="*"
            element={<NotFound error={"Page does not exist"} screenHeight />}
          />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
