import { useCallback, useContext, useEffect, useState } from "react";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import APIManager from "../scripts/APIManager";
import { AccountContext } from "../Contexts";
import AuthManager from "../scripts/AuthManager";
import { Button, Select, Skeleton, Space } from "antd";
import Badge from "../components/Badge";

export default function AtlassianForgeLink() {
  const { signedIn } = useContext(AccountContext);
  const [forgeUrl, setForgeUrl] = useState(null);
  const [guilds, setGuilds] = useState(null);
  const [selectedGuild, setSelectedGuild] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (forgeUrl === null) {
      const urlParams = new URLSearchParams(location.search);
      let newForgeUrl = urlParams.get("url");
      if (newForgeUrl) {
        setForgeUrl(newForgeUrl);
      } else setForgeUrl(false);
    }
  }, [location, forgeUrl]);

  const linkForge = useCallback(() => {
    setLoading(true);
    APIManager.sendRequest(
      "link_forge",
      {
        guild_id: selectedGuild,
        forge_url: forgeUrl,
      },
      true
    ).then(({ success }) => {
      if (success) navigate(`/${selectedGuild}/issues`);
    });
  }, [forgeUrl, selectedGuild, navigate]);

  const requestGuilds = useCallback((clearFirst = true) => {
    if (clearFirst) setGuilds(null);
    APIManager.sendRequest("get_guilds", {}, true).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        if (data.discord_linked) {
          setGuilds(data.guilds);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (signedIn && forgeUrl) {
      requestGuilds();
    }

    return () => {
      setGuilds(null);
      setSelectedGuild(null);
    };
  }, [signedIn, forgeUrl, requestGuilds]);

  useEffect(() => {
    if (signedIn === false && forgeUrl)
      navigate({
        pathname: "/login",
        search: `?${createSearchParams({
          to_atlassian_link: forgeUrl,
        })}&mode=signUp`,
      });
  }, [navigate, forgeUrl, signedIn]);

  if (error) return <h1>{error}</h1>;

  return (
    <div className="static min-h-screen flex flex-col bg-white dark:bg-black">
      <div className="grow flex flex-col bg-gradient-to-tr from-[#ffdc00]/20 via-transparent to-[#0099ff]/20">
        <div className="flex flex-col items-center gap-3 p-5">
          <div className="flex flex-row justify-between items-center w-full">
            <Button onClick={() => navigate("/home")}>Home</Button>
            <div className="flex flex-row items-center gap-1">
              <p>Signed in as:</p> <Badge>{AuthManager.username}</Badge>
            </div>
          </div>
          {forgeUrl === null || guilds === null ? (
            <div className="flex flex-col gap-5 w-60 px-4 sm:px-6 pt-2 pb-6">
              <Skeleton active className="mt-4" paragraph={{ rows: 2 }} />
            </div>
          ) : forgeUrl ? (
            <div className="flex flex-col gap-2 px-4 sm:px-6 pt-2 pb-6">
              <p>Choose a community to link to:</p>
              <Space.Compact size="large">
                <Select
                  className="w-60"
                  options={guilds.map(({ name, id }) => ({
                    value: id,
                    label: name,
                  }))}
                  placeholder="Select a community"
                  onChange={setSelectedGuild}
                />
                <Button
                  type="primary"
                  onClick={linkForge}
                  disabled={selectedGuild === null}
                  loading={loading}
                >
                  Link
                </Button>
              </Space.Compact>
            </div>
          ) : (
            <p>Invalid link</p>
          )}
        </div>
      </div>
    </div>
  );
}
