import { Button, Carousel } from "antd";
import { useContext, useMemo, useRef, useState } from "react";
import { SiteContext } from "../../Contexts";
import Modal from "../Modal";

export default function SetupJiraModal(props) {
  const { confirmSetup } = props;
  const { activeModal, setActiveModal } = useContext(SiteContext);
  const carousel = useRef();
  const [currentStep, setCurrentStep] = useState(0);

  const steps = useMemo(
    () => [
      <div className="flex flex-col gap-3 px-px">
        <h1>Welcome to the Issue Manager setup.</h1>
        <p className="text-lg">
          The final step of setup must take place within the Jira page attached
          to SpecifEye.
          <br />
          You will also need to add the SpecifEye UI module to your Jira site.
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Link the Jira App to a SpecifEye Community.</h1>
        <p className="text-lg">
          Go to <span className="font-bold">Settings</span> &gt;{" "}
          <span className="font-bold">Apps</span> to get to the{" "}
          <span className="font-bold">Manage Apps</span> page. From here, expand
          the section for the <span className="font-bold">SpecifEye</span> app
          and press the <span className="font-bold">Get Started</span> button.
        </p>
        <p className="text-lg">
          Follow the instructions on the{" "}
          <span className="font-bold">SpecifEye - Get Started</span> page.
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>Add the SpecifEye Panel.</h1>
        <p className="text-lg">
          To add the SpecifEye panel, navigate to the{" "}
          <span className="font-bold">project</span> you wish to add the module
          to and open up any <span className="font-bold">issue</span>.
        </p>
        <p className="text-lg">
          Select the <span className="font-bold">Apps</span> button near the top
          of the <span className="font-bold">issue</span> panel or window. You
          should see a dropdown containing{" "}
          <span className="font-bold">SpecifEye</span>. Select this and you
          should see it added to the <span className="font-bold">issue.</span>
        </p>
      </div>,
      <div className="flex flex-col gap-3 px-px">
        <h1>You are all set!</h1>
        <p className="text-lg">
          Congratulations! You are ready to start using our Jira integration.
        </p>
      </div>,
    ],
    []
  );

  return (
    <Modal
      className="w-full flex flex-col gap-10 mx-32 box-border p-10 overflow-hidden max-w-3xl"
      isActive={activeModal === "setup_jira"}
      onClose={() => {
        setActiveModal(null);
      }}
      onOpen={() => carousel.current?.goTo(0)}
    >
      <Carousel
        className="font-sans m-2"
        dots={false}
        ref={carousel}
        infinite={false}
        beforeChange={(_, next) => {
          if (currentStep !== null) setCurrentStep(next);
        }}
      >
        {steps.map((step, index) => (
          <div key={index}>{step}</div>
        ))}
      </Carousel>
      <div className="flex flex-row justify-between gap-3 m-2">
        <Button
          key="cancel_button"
          size="large"
          className="w-32 font-sans"
          onClick={() => {
            setActiveModal(null);
          }}
        >
          Cancel
        </Button>
        <div className="flex flex-row gap-3">
          {currentStep !== 0 ? (
            <Button
              key="back_button"
              size="large"
              className="w-32 font-sans"
              onClick={() => {
                carousel.current?.prev();
              }}
            >
              Previous
            </Button>
          ) : null}
          {currentStep < steps.length - 1 ? (
            currentStep === 0 ? (
              <Button
                key="start_tutorial_button"
                disabled={currentStep + 1 >= steps.length}
                type="primary"
                size="large"
                className="font-sans"
                onClick={() => {
                  carousel.current?.next();
                }}
              >
                Start Tutorial
              </Button>
            ) : (
              <Button
                key="next_button"
                disabled={currentStep + 1 >= steps.length}
                size="large"
                className="w-32 font-sans"
                onClick={() => {
                  carousel.current?.next();
                }}
              >
                Next
              </Button>
            )
          ) : (
            <Button
              key="confirm_setup"
              type="primary"
              size="large"
              className="w-32 font-sans"
              onClick={confirmSetup}
            >
              Confirm
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
