import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Skeleton } from "antd";
import { useCallback, useContext, useEffect, useState } from "react";
import { SiteContext } from "../../Contexts";
import Card from "../../components/Card";
import IssueList from "../../components/issues/IssueList";
import SetupJiraModal from "../../components/issues/SetupJiraModal";
import APIManager from "../../scripts/APIManager";

export default function Issues() {
  const { guildId, jiraSite, setActiveModal, refreshGuild, openNotification } =
    useContext(SiteContext);
  const [issues, setIssues] = useState(null);
  const [addedApp, setAddedApp] = useState(false);
  const [error, setError] = useState(null);

  const getRelevantIssues = useCallback(() => {
    APIManager.sendRequest(
      "get_jira_relevant_issues",
      {
        guild_id: guildId,
      },
      true
    ).then((data) => {
      if (data.error) setError(`error: ${data.error}`);
      else {
        setIssues([...data.issues, ...data.backlog_issues]);
        console.log(data);
      }
    });
  }, [guildId, setIssues]);

  useEffect(() => {
    if (jiraSite) {
      getRelevantIssues();
    }
  }, [jiraSite, getRelevantIssues]);

  if (error) return <h1>{error}</h1>;

  return (
    <>
      <div className="flex flex-row justify-between items-start m-2">
        <h1 className="softer-text">Issues</h1>
      </div>
      {jiraSite === null ? (
        <div className="flex flex-row justify-around">
          <div className="flex flex-col items-stretch gap-5 max-w-xl w-full">
            <Card className="h-fit p-4 gap-4">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-3">
                  <h1 className={`${addedApp ? "opacity-30" : ""}`}>
                    <FontAwesomeIcon
                      icon={addedApp ? faCheckCircle : faCircle}
                      className="pr-3"
                    />
                    <span className="font-normal">Step 1 - </span>
                    Add App to Jira
                  </h1>
                </div>
              </div>
              <div>
                {addedApp ? (
                  <p className="opacity-30">COMPLETED</p>
                ) : (
                  <div className="flex flex-row gap-2">
                    <Button
                      size="large"
                      type="primary"
                      target="_blank"
                      href="https://developer.atlassian.com/console/install/c30fd5a0-f38d-4b01-a695-3c4608254f1d?signature=AYABeEDhsq5mgFuehQuCiLwW6%2FgAAAADAAdhd3Mta21zAEthcm46YXdzOmttczp1cy1lYXN0LTE6NzA5NTg3ODM1MjQzOmtleS83ZjcxNzcxZC02OWM4LTRlOWItYWU5Ny05MzJkMmNhZjM0NDIAuAECAQB4KZa3ByJMxgsvFlMeMgRb2S0t8rnCLHGz2RGbmY8aB5YBH7c6rKzQe9aGS8cB1cOMTAAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDIF40mXpydepqO6mRgIBEIA7PsNmun7OrfrMvfcDpECD7hD7o%2F8dPfY%2BRL%2FelgxkhDDFnxfAXd1WLdZ4w%2FOmvVaFb5O%2Fos69496SGu4AB2F3cy1rbXMAS2Fybjphd3M6a21zOmV1LXdlc3QtMTo3MDk1ODc4MzUyNDM6a2V5LzU1OWQ0NTE2LWE3OTEtNDdkZi1iYmVkLTAyNjFlODY4ZWE1YwC4AQICAHhHSGfAZiYvvl%2F9LQQFkXnRjF1ris3bi0pNob1s2MiregH2mBTCgwqDRwLc5eJ65IFuAAAAfjB8BgkqhkiG9w0BBwagbzBtAgEAMGgGCSqGSIb3DQEHATAeBglghkgBZQMEAS4wEQQMDTi%2BeXbMAaDC7msAAgEQgDs83qpogoOkRn94OG6rS%2FOqXcAspZ9ynislRTLMeSBdcyKV3SlvhQQFpLfk7tj5ThdMtN%2FPSWcexCUBfQAHYXdzLWttcwBLYXJuOmF3czprbXM6dXMtd2VzdC0yOjcwOTU4NzgzNTI0MzprZXkvM2M0YjQzMzctYTQzOS00ZmNhLWEwZDItNDcyYzE2ZWRhZmRjALgBAgIAePadDOCfSw%2BMRVmOIDQhHhGooaxQ%2FiwGaLB334n1X9RCATJ2PLCQ2BgRk3MeN%2F0lWpsAAAB%2BMHwGCSqGSIb3DQEHBqBvMG0CAQAwaAYJKoZIhvcNAQcBMB4GCWCGSAFlAwQBLjARBAyKyC21pq9E5ioPdrICARCAO2Rc4bRyBxqZLaltQP4gD%2BOy43N%2FeZtH75TEUJhnDe5P5P7Rhq6jeWe81%2BoETz%2Bgf1IG8ZXmFjZ7Wx46AgAAAAAMAAAQAAAAAAAAAAAAAAAAAMess8iP6ouGAQoFznGlCZX%2F%2F%2F%2F%2FAAAAAQAAAAAAAAAAAAAAAQAAADL3QqohQ3ac9ASs4ZZW5BV%2FHb7Z%2FGRkI9uPi3qbW%2Fb9g5NCtMsg895Uf1GapLghZbX8p3MvBLXt%2Ffb7axlLTsg8Q3Y%3D&product=jira"
                    >
                      Add Jira App
                    </Button>
                    <Button size="large" onClick={() => setAddedApp(true)}>
                      Done
                    </Button>
                  </div>
                )}
              </div>
            </Card>
            <Card className="h-fit p-4 gap-4">
              <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-3">
                  <h1
                    className={`${
                      jiraSite !== null || !addedApp ? "opacity-30" : ""
                    }`}
                  >
                    <FontAwesomeIcon
                      icon={jiraSite !== null ? faCheckCircle : faCircle}
                      className="pr-3"
                    />
                    <span className="font-normal">Step 2 - </span>
                    Link Jira Site
                  </h1>
                </div>
              </div>
              <div>
                {jiraSite !== null ? (
                  <p className="opacity-30">COMPLETED</p>
                ) : (
                  <Button
                    size="large"
                    type="primary"
                    onClick={() => setActiveModal("setup_jira")}
                    disabled={!addedApp}
                  >
                    Link Site
                  </Button>
                )}
              </div>
            </Card>
          </div>
        </div>
      ) : issues ? (
        <IssueList issues={issues} guildId={guildId} />
      ) : (
        <Skeleton active className="mt-2" paragraph={{ rows: 6 }} />
      )}

      <SetupJiraModal
        confirmSetup={() => refreshGuild().then(() => setActiveModal(null))}
      />
    </>
  );
}
