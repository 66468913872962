import { faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Table, Tooltip } from "antd";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { SiteContext } from "../../Contexts";
import APIManager from "../../scripts/APIManager";
import Helper from "../../scripts/Helper";
import Badge from "../Badge";
import Modal from "../Modal";

export default function IssueList(props) {
  const { className, issues, guildId } = props;
  const { setActiveModal, permissions } = useContext(SiteContext);
  const [selectedIndexes, setSelectedIndexes] = useState([]);
  const [selecteedItems, setSelectedItems] = useState([]);
  const [expandedData, setExpandedData] = useState(null);
  const [expandedRecord, setExpandedRecord] = useState(null);
  const [modalExpanded, setModalExpanded] = useState(false);
  const [expanded, setExpanded] = useState(false);

  const expandedEndRef = useRef(null);

  const fields = [
    Table.SELECTION_COLUMN,
    {
      title: "Summary",
      key: "summary",
      dataIndex: ["fields", "summary"],
    },
    {
      title: "Status",
      key: "status",
      dataIndex: ["fields", "status"],
      render: ({ name }) => (
        <div className="flex flex-row items-center gap-2">
          <Badge>{name}</Badge>
        </div>
      ),
    },
    // {
    //   title: "Sprint",
    //   key: "sprint",
    //   dataIndex: ["fields", "sprint"],
    //   render: (sprint) =>
    //     sprint ? (
    //       <Badge>{sprint?.name || <span className="italic">None</span>}</Badge>
    //     ) : (
    //       <Badge>Backlog</Badge>
    //     ),
    // },
    {
      title: "Assignee",
      key: "assignee",
      dataIndex: ["fields", "assignee"],
      render: (assignee) =>
        assignee ? (
          <Badge>
            {assignee?.displayName || <span className="italic">None</span>}
          </Badge>
        ) : (
          <p className="italic softer-text">Unassigned</p>
        ),
    },
  ];

  useEffect(() => setModalExpanded(false), [issues]);

  return (
    <>
      <Table
        className={className}
        rowClassName={() => "cursor-pointer"}
        dataSource={issues.map((item) => ({
          ...item,
          key: item.id,
        }))}
        tableLayout="auto"
        columns={fields}
        // loading={retrievingData}
        // onChange={(pagination, filters, sorter) => {
        //   setTableSettings({ pagination, filters, sorter });
        // }}
        size="small"
        scroll={{ x: "max-content" }}
        // pagination={{
        //   current: pageNumber,
        //   defaultPageSize: 10,
        //   pageSize,
        //   hideOnSinglePage: false,
        //   showSizeChanger: true,
        //   total: reportsCount,
        //   onChange: (page, pageSize) => {
        //     setPageNumber(page);
        //     setPageSize(pageSize);
        //     setSelectedIndexes([]);
        //     setSelectedItems([]);
        //   },
        // }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              setExpandedRecord(record);
              setExpandedData(record); // This needs to be a new list
              setModalExpanded(true);
            },
          };
        }}
        rowSelection={{
          selectedRowKeys: selectedIndexes,
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectedIndexes(selectedRowKeys);
            setSelectedItems(selectedRows);
          },
        }}
      />
      <Modal
        className="max-w-5xl gap-0 mx-6"
        isActive={modalExpanded}
        onClose={() => setModalExpanded(false)}
        onOpen={() =>
          expandedEndRef.current?.scrollIntoView({ behavior: "smooth" })
        }
        header={
          <div className="flex flex-row items-center gap-3">
            <div className="flex flex-row items-center gap-1.5">
              <p>
                <span className="font-bold">Issue:</span>{" "}
                {expandedRecord?.fields.summary}
              </p>
            </div>
          </div>
        }
        closeButton
      >
        {!!expandedData ? (
          <div className="relative max-h-full box-border -m-2 min-h-[310px] overflow-y-hidden">
            <div className="flex flex-col max-h-full box-border pb-4 px-2 mr-12 overflow-y-auto">
              <div className="flex flex-row p-2">
                <p>Context</p>
              </div>
              <div className="overflow-y-auto h-full">
                {/* <Table
                  className="grow filled-header"
                  sticky={true}
                  tableLayout="auto"
                  // dataSource={expandedData.map((message) => ({
                  //   ...message,
                  //   key: message.entry_id,
                  // }))}
                  // columns={messageColumns}
                  size="small"
                  pagination={false}
                />
                <div ref={expandedEndRef} /> */}
              </div>
            </div>
            <div
              className={`absolute right-0 top-0 bottom-0
              flex flex-col box-border
              ${expanded ? "w-80" : "w-12"}
              h-full transition-all overflow-hidden
              bg-white dark:bg-dark-white z-50
              border-0 border-l border-solid border-black/10 dark:border-[#303030] shadow-lg`}
              onMouseEnter={() => {
                setExpanded(true);
              }}
              onMouseLeave={() => {
                setExpanded(false);
              }}
            >
              <div className="flex flex-row items-center gap-4 p-2 mx-2 border-0 border-b border-solid border-black/10 dark:border-[#303030]">
                <FontAwesomeIcon icon={faList} />
                <p>Details</p>
              </div>
              <div
                className={`relative grow p-2
                after:absolute after:content-[''] after:inset-0 after:transition-all
                after:bg-gradient-to-r after:from-transparent after:to-white after:dark:to-dark-white
                ${expanded ? "after:left-full" : "after:left-0"}`}
              >
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">
                    Assignee
                  </p>
                  <Badge className="sm:max-w-[6rem]" tooltip>
                    {expandedRecord.fields.assignee?.displayName || (
                      <span className="italic">None</span>
                    )}
                  </Badge>
                </div>
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">
                    Reporter
                  </p>
                  <Badge className="sm:max-w-[6rem]" tooltip>
                    {expandedRecord.fields.reporter?.displayName || (
                      <span className="italic">None</span>
                    )}
                  </Badge>
                </div>
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">
                    Time Created
                  </p>
                  <Tooltip
                    title={`${new Date(
                      expandedRecord.fields.created
                    ).toLocaleTimeString("en-us", {
                      weekday: "short",
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                      hour: "2-digit",
                      minute: "2-digit",
                    })}`}
                  >
                    {Helper.timeAgo(expandedRecord.fields.created)}
                  </Tooltip>
                </div>
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">
                    Status
                  </p>
                  <Badge className="sm:max-w-[6rem]" tooltip>
                    {expandedRecord.fields.status?.name || (
                      <span className="italic">None</span>
                    )}
                  </Badge>
                </div>
                <div className="flex flex-row items-center px-2 whitespace-nowrap">
                  <p className="shrink-0 softer-text text-sm w-24 py-2">
                    Sprint
                  </p>
                  <Badge className="sm:max-w-[6rem]" tooltip>
                    {expandedRecord.fields.sprint?.name || (
                      <span className="italic">None</span>
                    )}
                  </Badge>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </Modal>
    </>
  );
}
